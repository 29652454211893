import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Ganapathi Engineering About.</span></h4>
                                    <h2 className="title mt--10">About Our Business.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Ganapathi engineering is one among the promising industry in the plastic injection mould and moulding
division. It creats with a strong presence in segment of automobile, electrical, eletronic, engineering products, EBM(Extrusion blow mould), ISBM(injection stretch blow mould), IBM(Injection blow mould), cutlery and home appliances moulds. </p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> To acquire and maintain global leadership position in chosen area of business,</li>
                                        <li><span className="icon"><FiCheck /></span> To continuously create new opportunities for growth in our strategic businesses,</li>
                                        <li><span className="icon"><FiCheck /></span> To be among the top 10 most admired companies to work for,</li>
                                        <li><span className="icon"><FiCheck /></span> To continuously show improvements in field of execution.</li>
                                    </ul>
                                </ScrollAnimation>
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="#">More About Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
