import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:044-22254122">044-22254122</a></p>
                                        <p><a href="tel:+91-8939800801">+91-8939800801</a>, <a href="tel:+91-8939800802">+91-8939800802</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:ganapathiengineering@gmail.com">ganapathiengineering@gmail.com</a></p>
                                        <p>&nbsp;</p>
                                        {/* <p><a href="mailto:vijay@ganapathi.net.in">vijay@ganapathi.net.in</a></p>
                                        <p><a href="mailto:madhanlal@ganapathi.net.in">madhanlal@ganapathi.net.in</a></p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>NO: 44/36, PILLAY AR KOIL 1ST STREET, EKKATTUTHANGAL, CHENNAI - 600032.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>
        </>
    )
}
export default ContactOne;